// Bootstrap Framework
import jquery from "jquery";
window.$ = window.jQuery = jquery; //Global
import "./scss/index.scss";
import "popper.js";
import "bootstrap";
import "slick-carousel";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

$(".slider-news").slick({
    dots: true,
    arrows: false,
});

$(document).ready(function () {

    var _day = 1;
    var _month = 11;
    var _year = new Date().getFullYear();

     //Set Date CountDown
     $.ajax({
        url: "../handlers/Getdatecountdown.ashx",
        type: 'GET',
        success: function (succ) {
            //debugger;
            if (Date.parse(new Date(succ))) {
                var well_formatted_date = new Date(succ);
                var event_date = new Date(well_formatted_date.getFullYear(), well_formatted_date.getMonth(), well_formatted_date.getDate());

                _day = event_date.getDate();
                _month = event_date.getMonth() + 1;
                _year = event_date.getFullYear();

                //Controllo se la data � passata, nascondo il contatore e faccio visualizzare il div "even-started"
                var current_date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
                console.log("event_date: " + event_date);
                console.log("current_date: " + current_date)
                if (current_date >= event_date)
                {
                    $('.even-started').removeClass('d-none');
                    $('.ce-countdown').addClass('d-none');
                }
            }
            else {
                // console.log("Invalid date")
            }

            $(".ce-countdown").countEverest({
                // Set your target date here!
                day: _day,
                month: _month,
                year: _year,
                leftHandZeros: false,
                onChange: function () {
                    drawCircle($("#ce-days").get(0), this.days, 365);
                    drawCircle($("#ce-hours").get(0), this.hours, 24);
                    drawCircle($("#ce-minutes").get(0), this.minutes, 60);
                    drawCircle($("#ce-seconds").get(0), this.seconds, 60);
                },
            });



        },
        error: function (err) {
            console.log('error: ' + err.status);
        }





    });

     $('.voce_glossario').each(function () {
       
         $(this).attr('tabindex', '0');
         $(this).attr('role', 'button');
         $(this).attr('data-toggle', 'popover');
         $(this).attr('data-trigger', 'focus');
         $(this).attr('data-placement', 'bottom');
         $(this).attr('title', $('li[rel=' + $(this).attr('rel') + ']').children('h3').first().text());
         $(this).attr('data-content', $('li[rel=' + $(this).attr('rel') + ']').children('p').first().text());

     }).click(function (event) { event.preventDefault(); }).popover();


    function deg(v) {
        return (Math.PI / 180) * v - Math.PI / 2;
    }

    function drawCircle(canvas, value, max) {
        var primaryColor = "#D86B05",
            secondaryColor = "#ffffff",
            circle = canvas.getContext("2d");

        circle.clearRect(0, 0, canvas.width, canvas.height);
        circle.lineWidth = 8;

        circle.beginPath();
        circle.arc(
            canvas.width / 2,
            canvas.height / 2,
            canvas.width / 2 - circle.lineWidth,
            deg(0),
            deg((360 / max) * (max - value)),
            false
        );
        circle.strokeStyle = secondaryColor;
        circle.stroke();

        circle.beginPath();
        circle.arc(
            canvas.width / 2,
            canvas.height / 2,
            canvas.width / 2 - circle.lineWidth,
            deg(0),
            deg((360 / max) * (max - value)),
            true
        );
        circle.strokeStyle = primaryColor;
        circle.stroke();
    }
});
